import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div style={{
      "textAlign": "center"
    }}>
      <img alt="Inkchievous" src="./logo.png" width="50%" />
      <h3>Original Art, Creatures, and Landscapes</h3>
      <p><a href="https://instagram.com/inkchievous">Follow me for updates and art drops.</a></p>
      <p><a href="https://shop.inkchievous.com">Visit my shop to support my work.</a></p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      